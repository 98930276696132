<template>
  <article :class="['bob', {'is--mobile': isMobileOnly}]">
    <div class="layout small">
      <section-title v-if="!isMobileOnly">
        <template v-slot:title>
          보리BOB
        </template>
      </section-title>
      <article v-if="ingData.length > 0" class="bob-content progress">
        <h2 class="bob-content__title">진행중인 캠페인</h2>
        <div class="bob-content-list">
          <a
            v-for="(ing, idx) in ingData"
            :key="idx"
            :href="ing.url"
            class="bob-content__item"
            target="_blank"
          >
            <div class="bob-content-top">
              <strong class="bob-content__subject">
                {{ ing.title }}
              </strong>
              <span
                v-if="!isMobileOnly && !ing.always"
                class="bob-content__date"
              >
                {{ convertYmd(ing.start) }} ~ {{ convertYmd(ing.end) }}
              </span>
              <mark
                v-if="!isMobileOnly && ing.always"
                class="bob-content__label"
              >
                상시모금
              </mark>
            </div>
            <div class="bob-content__content">
              {{ ing.body || ing.title }}
            </div>
            <div class="bob-content-platform">
              <div class="bob-content-platform">
                <span class="platform__logo">
                  <img :src="ing.icon" alt="" class="platform__logo-img">
                </span>
                <span class="platform__text">
                  {{ ing.company }}
                </span>
              </div>
              <span
                class="bob-content__date"
                v-if="isMobileOnly && !ing.always"
              >
                {{ convertYmd(ing.start) }} ~ {{ convertYmd(ing.end) }}
              </span>
              <mark
                v-if="isMobileOnly && ing.always"
                class="bob-content__label"
              >
                상시모금
              </mark>
            </div>
          </a>
        </div>
      </article>
      <article v-if="endData.length > 0" class="bob-content">
        <h2 class="bob-content__title">종료된 캠페인</h2>
        <div class="bob-content-list">
          <a
            v-for="(end, idx) in endData"
            :key="idx"
            :href="end.url"
            class="bob-content__item"
            target="_blank"
          >
            <div class="bob-content-top">
              <strong class="bob-content__subject">
                {{ end.title }}
              </strong>
              <span
                v-if="!isMobileOnly"
                class="bob-content__date"
              >
                {{ convertYmd(end.start) }} ~ {{ convertYmd(end.end) }}
              </span>
              <mark
                v-if="!isMobileOnly && end.always"
                class="bob-content__label"
              >
                상시모금
              </mark>
            </div>
            <div class="bob-content__content">
              {{ end.body || end.title }}
            </div>
            <div class="bob-content-platform">
              <div class="bob-content-platform">
                <span class="platform__logo">
                  <img :src="end.icon" alt="" class="platform__logo-img">
                </span>
                <span class="platform__text">{{ end.company }}</span>
              </div>
              <span
                v-if="isMobileOnly && !end.always"
                class="bob-content__date"
              >
                {{ convertYmd(end.start) }} ~ {{ convertYmd(end.end) }}
              </span>
              <mark
                v-if="isMobileOnly && end.always"
                class="bob-content__label"
              >
                상시모금
              </mark>
            </div>
          </a>
        </div>
      </article>
      <div
        v-if="showEndDataLoading"
        class="loading-area">
        <span class="loading">Loading..</span>
      </div>
    </div>
  </article>
</template>

<script>
import { defineComponent } from 'vue'
import { isMobileOnly } from 'mobile-device-detect'
import SectionTitle from '@/components/layout/SectionTitle'
import { onMounted, onUnmounted, ref } from 'vue'
import axios from 'axios'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'BobList',
  components: {
    SectionTitle
  },
  setup () {
    const ingData = ref([])
    const endData = ref([])
    const endPage = ref(0)
    const showEndDataLoading = ref(false)
    const canEndDataFetch = ref(true)

    const fetchData = async ({ page = 0, pageSize = 10, status }) => {
      const { data } = await axios.get(`${process.env.VUE_APP_API_HOST}/api/common/funds`, {
        params: {
          page,
          pageSize,
          status
        }
      })
      return data
    }

    const fetchIngData = async () => {
      const { data } = await fetchData({page: 0, pageSize: 1000, status: 'fundraising'})
      ingData.value = data
    }

    const fetchEndData = async (page) => {
      try {
        if (canEndDataFetch.value) {
          canEndDataFetch.value = false
          showEndDataLoading.value = true

          const { data, total } = await fetchData({page, pageSize: 30, status: 'end'})
          endData.value = endData.value.concat(data)
          endPage.value = page

          canEndDataFetch.value = endData.value.length < total
          showEndDataLoading.value = false
        }
      } catch (e) {
        canEndDataFetch.value = false
        showEndDataLoading.value = false
        alert('데이터 로드 실패.')
      }
    }

    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight
      const scrollTop = document.documentElement.scrollTop
      const clientHeight = document.documentElement.clientHeight
      const footerHeight = document.querySelector('.footer').clientHeight
      const loadingMargin = isMobileOnly ? 0 : 180
      // console.log(scrollHeight, scrollTop, clientHeight)
      if (scrollTop + clientHeight + footerHeight + loadingMargin >= scrollHeight && canEndDataFetch.value) {
        // 페이지 끝에 도달하면 추가 데이터를 받아온다
        fetchEndData(endPage.value + 1)
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
      fetchIngData()
      fetchEndData(endPage.value)
    })
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    const convertYmd = (date) => {
      if (!date) {
        return ''
      }
      return dayjs(date).format('YYYY.MM.DD')
    }

    return {
      ingData,
      endData,
      showEndDataLoading,
      isMobileOnly,
      convertYmd
    }
  }
})
</script>

<style scoped lang="scss">
.bob-content {
  color: #999999;
  & + .bob-content {
    margin-top: 80px;
  }

  &__title {
    @include setText(20, bold, 23);
    letter-spacing: -0.05em;
    margin-bottom: 30px;
    color: #333333;
  }

  &__item {
    display: block;
    padding: 1.56vw;
    border: 1px solid #EEEEF1;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: $white;

    & + .bob-content__item {
      margin-top: 30px;
    }
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__date {
    letter-spacing: -0.01em;
    @include setText(14, normal, 18);
    flex: none;
    @include respond-to() {
      font-size: 11px;
    }
  }
  &__label {
    padding: 6px 10px;
    display: inline-block;
    letter-spacing: -0.01em;
    @include setText(14, normal, 18);
    border-radius: 20px;
    color: #666666;
    background: rgba(44, 33, 26, 0.2);
    @include respond-to() {
      font-size: 11px;
    }
  }


  &__subject {
    @include setText(28, normal, 34);
    letter-spacing: -0.01em;
    width: calc(100% - 175px);
  }

  &__content {
    letter-spacing: -0.01em;
    @include setText(16, normal, 18);
    width: calc(100% - 175px);
    word-break: keep-all;
    margin: 15px 0 20px;
    color: #666;
    @include respond-to() {
      font-size: 12px;
    }
  }

  &-platform {
    display: flex;
    align-items: center;
    .platform__ {
      &logo {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #F5F5F5;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &-img {
          object-fit: contain;
        }
      }

      &text {
        @include setText(12, normal, 18);
        letter-spacing: -0.01em;
        color: #333333;
        margin-left: 8px;
      }
    }
  }

  &.progress {
    color: #333333;

    .bob-content {
      &__title,
      &__date {
        color: $point2;
      }
      &__item {
        border-color: $point2;
      }
      &__label {
        color: #333333;
        background: rgba(77, 206, 196, 0.2);
      }
    }
  }
}

.loading-area {
  margin-bottom: 180px;
}

.bob {
  &.is--mobile {
    padding-top: 30px;
    .layout.small {
      width: 100%;
    }
    .bob-content__item {
      padding: 5.55vw 4.4vw 4.4vw;
      & + .bob-content__item {
        margin-top: 20px;
      }
    }
    .bob-content {
      & + .bob-content {
        margin-top: 48px;
        padding-bottom: 30px;
      }
      &__title {
        margin-bottom: 20px;
      }
      &__subject {
        @include setText(16, normal, 20);
        width: 100%;
      }
      &__content {
        width: 100%;
        margin: 8px 0 16px;
      }
      &-platform {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .platform__ {
          &logo {
            width: 32px;
            height: 32px;
          }
        }
      }
      .bob-content__label {
        padding: 6px 8px;
        @include setText(11, normal, 14);
      }
      &.progress {
        .bob-content__label {
          background: $point2;
          color: $white;
        }
      }

    }
    .loading-area {
      padding: 40px 0;
      margin-bottom: 0;
    }
  }
}
</style>
